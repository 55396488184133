import { IncomingMessage } from 'http';
import parser from 'accept-language-parser';
import i18next, { i18n } from 'i18next';
import { LangCountry } from '../models/app';
import { DEFAULT_COUNTRY, SUPPORTED_COUNTRY } from './countries';

export const DEFAULT_LANG: STRING_LANG = 'en';
export const SUPPORTED_LANG = [
  'it',
  'en',
  'de',
  'fr',
  'es'
  // 'sv',
  // 'no',
  // 'cz',
  // 'sk',
  // 'pl',
  // 'nl'
];

export type STRING_LANG = 'it' | 'en' | 'de' | 'fr' | 'es';
// | 'sv'
// | 'no'
// | 'cz'
// | 'sk'
// | 'pl'
// | 'nl';

export const detectLang = (req?: IncomingMessage): string => {
  let code = '';

  if (req) {
    const accepted = req.headers['accept-language'];
    const languages = parser.parse(accepted || '');
    if (languages.length > 0) {
      code = languages[0].code;
    }
  } else {
    code = i18next.language;
  }

  return SUPPORTED_LANG.includes(code) ? code : DEFAULT_LANG;
};

export const getLangCountry = (query: any): LangCountry => {
  const { lang = '' } = query || {};

  const splitted = lang.split('_');
  const finalLang = SUPPORTED_LANG.includes(splitted[0])
    ? splitted[0]
    : DEFAULT_LANG;
  const finalCountry = SUPPORTED_COUNTRY.includes(splitted[1])
    ? splitted[1]
    : DEFAULT_COUNTRY;
  return {
    lang: finalLang,
    country: finalCountry
  };
};

export const getRealLangCountry = (query: any): LangCountry => {
  const { lang = '' } = query;
  const splitted = lang.split('_');
  return {
    lang: splitted[0],
    country: splitted[1]
  };
};

export const getAlternateLangs = (currentLang: string): string[] => {
  return SUPPORTED_LANG.filter(lang => lang !== currentLang);
};

export const getLangFromI18n = (i18n: i18n): string => {
  const { language = '' } = i18n;
  return language.slice(0, 2);
};
