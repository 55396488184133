import axios from 'axios';
import { ApiCommonParams, ResponseWithError } from '../models/app';

export const createSession = async ({
  country
}: {
  country: string;
}): Promise<ResponseWithError> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/session/`;

  try {
    const response = await axios.post(
      url,
      {},
      {
        headers: {
          ['UYN-COUNTRY']: country
        }
      }
    );

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchSession = async ({
  token,
  country,
  lang
}: ApiCommonParams): Promise<ResponseWithError> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/session/`;

  try {
    if (!token) {
      throw { error: 'No token' };
    }

    const response = await axios({
      method: 'GET',
      url,
      headers: {
        ['UYN-BEARER']: token,
        ['UYN-COUNTRY']: country,
        ['UYN-LANGUAGE']: lang
      }
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    return { error };
  }
};
