import '../styles/globals.css';
import 'react-multi-carousel/lib/styles.css';
import Layout from '../components/Layout';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from '../public/locales/en.json';
import translationIt from '../public/locales/it.json';
import translationEs from '../public/locales/es.json';
import translationDe from '../public/locales/de.json';
import translationFr from '../public/locales/fr.json';
import translationNo from '../public/locales/no.json';
import translationSv from '../public/locales/sv.json';
import moment from 'moment';
import { DEFAULT_LANG, getLangCountry } from '../helpers/languages';
import LanguageDetector from 'i18next-browser-languagedetector';
import { JeepAppProps } from '../models/app';
import { AlertProvider } from '../context/AlertProvider';
import dynamic from 'next/dynamic';
import GoogleTagManagerBody from '../components/GoogleTagManagerBody';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import * as gTag from '../lib/gtag';
import { Provider } from 'react-redux';
import store from '../context/store';
import AdviseCountry from '../components/AdviseCountry';
import { useCookies } from 'react-cookie';
import { DEFAULT_COUNTRY } from '../helpers/countries';
import { createSession, fetchSession } from '../fetchers/sessions';
import {
  SET_PROFILE,
  SET_SESSION_DATA,
  SET_SESSION_TOKEN
} from '../context/types';
import { getGeolocationCountry, getMyIp } from '../fetchers/geolocation';
import { fetchProfileSettings } from '../fetchers/profile';
import { logoutActions } from '../helpers/logout';
import { getCookieLogged } from '../helpers/session';

import TagManager from 'react-gtm-module';

const ChatWithNoSSR = dynamic(() => import('../components/Chat'), {
  ssr: false
});

declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

// var _iub = _iub || [];
// _iub.csConfiguration = { "consentOnContinuedBrowsing": false, "whitelabel": false, "lang": "it", "cookiePolicyId": 28601124, "siteId": 2352888, "banner": { "acceptButtonDisplay": true, "customizeButtonDisplay": true, "position": "float-top-center", "acceptButtonColor": "#0073CE", "acceptButtonCaptionColor": "white", "customizeButtonColor": "#DADADA", "customizeButtonCaptionColor": "#4D4D4D", "rejectButtonColor": "#0073CE", "rejectButtonCaptionColor": "white", "textColor": "black", "backgroundColor": "white" } };

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEn
      },
      it: {
        translation: translationIt
      },
      es: {
        translation: translationEs
      },
      de: {
        translation: translationDe
      },
      fr: {
        translation: translationFr
      },
      no: {
        translation: translationNo
      },
      sv: {
        translation: translationSv
      }
    },
    fallbackLng: DEFAULT_LANG,
    interpolation: {
      escapeValue: false
    }
  });

function JeepApp({ Component, pageProps }: JeepAppProps) {
  const router = useRouter();
  const { lang = DEFAULT_LANG, country = DEFAULT_COUNTRY } = getLangCountry(
    router.query
  );
  const [cookies, setCookie, removeCookie] = useCookies([
    'jeep_bearer',
    'jeep_country'
  ]);

  i18next.changeLanguage(lang);
  moment.locale(lang);

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      gTag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (router.query?.lang) {
      setCookie(
        'jeep_country',
        country && country !== 'undefined' ? country : DEFAULT_COUNTRY,
        { path: '/' }
      );
      setupSession();
    }
  }, [router.query]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_GTM as string
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const getNewSessionToken = async (): Promise<string> => {
    const { headers = {} } = await createSession({ country });

    return headers['uyn-bearer'];
  };

  const setupSession = async () => {
    let token = cookies['jeep_bearer'];

    const { data, error } = await fetchSession({ token, country, lang });

    if (error) {
      token = await getNewSessionToken();
    }
    if (!token) {
      return;
    }

    setCookie('jeep_bearer', token, {
      path: '/',
      expires: moment().add(7, 'days').toDate()
    });
    store.dispatch({ type: SET_SESSION_DATA, payload: data?.session });
    store.dispatch({ type: SET_SESSION_TOKEN, payload: token });

    // se loggato chiamo la /me
    const logged = getCookieLogged();
    if (logged) {
      const { error, data } = await fetchProfileSettings({
        lang,
        country,
        token
      });
      if (error) {
        logoutActions(removeCookie, store);
        setupSession();
      } else {
        store.dispatch({
          type: SET_PROFILE,
          payload: data.user_info
        });
      }
    }
  };

  return (
    <>
      <Provider store={store}>
        <AlertProvider>
          <Layout>
            <AdviseCountry />
            <Component {...pageProps} />
            {/* <GoogleTagManagerBody /> */}
            <ChatWithNoSSR />
          </Layout>
        </AlertProvider>
      </Provider>
    </>
  );
}

export default JeepApp;
