import Image from 'next/image';

export default function StyledImage({
  src,
  alt = '',
  className = ''
}: {
  src: any;
  alt?: string;
  className?: string;
}) {
  return (
    <div className={className}>
      <Image src={src} alt={alt} />
    </div>
  );
}
