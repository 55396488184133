import axios from 'axios';

export interface Geolocation {
  geolocation: {
    storeview: string;
    country: string;
  };
}

export const getGeolocationCountry = async (
  ip_address: string
): Promise<string> => {
  const url = `${
    process.env.NEXT_PUBLIC_WP_API_URL || process.env.WP_API_URL
  }/v1/geolocation`;

  try {
    const { data } = await axios.get<Geolocation>(url, {
      params: {
        ip_address
      }
    });

    const { geolocation } = data || {};
    const { country = '' } = geolocation || {};
    return country;
  } catch (error) {
    return '';
  }
};

export const getMyIp = async (): Promise<string> => {
  try {
    const { data } = await axios.get<string>('https://api.ipify.org/');
    return data;
  } catch (error) {
    return '';
  }
};

export const fetchAdvisedUrl = async ({
  url,
  lang,
  country
}: {
  url: string | string[];
  lang: string;
  country: string;
}): Promise<any> => {
  try {
    const URL = `${process.env.NEXT_PUBLIC_WP_API_URL}/v1/translate-url`;

    const { data } = await axios.get(URL, {
      params: { url: Array.isArray(url) ? `/${url.join('/')}` : url },
      headers: {
        'UYN-LANGUAGE': lang,
        'UYN-COUNTRY': country
      }
    });

    return data;
  } catch (error) {
    return '';
  }
};
