import { ICountryListItem } from '../models/footer';

export const DEFAULT_COUNTRY = 'gb';
export const DEFAULT_SYMBOL = '£';

export const SUPPORTED_COUNTRY = ['it', 'gb', 'de', 'ch', 'fr', 'es', 'int'];

export const getFlagCode = (country: ICountryListItem | null): string => {
  return (country && country['country-code']) || DEFAULT_COUNTRY;
};

export const getCountryName = (country: ICountryListItem | null): string => {
  return (country && country['country-name']) || '';
};
