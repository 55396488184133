import { useState, useEffect } from 'react';
import { isServer } from '../helpers/platform';

function getSessionStorageOrDefault(key: string, defaultValue?: string) {
  if (isServer()) {
    return '';
  }
  const stored = sessionStorage.getItem(key);
  return stored || defaultValue || '';
}

export function useSessionStorage(key: string, defaultValue?: string) {
  const [value, setValue] = useState<string>(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    sessionStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue];
}
